import { TEAMS_OBJ } from 'constants/cricket'
import React from 'react'

function WidgetTable({ pointsData, table, size, IMAGE_BASE_URL, desktop = true, group }) {


    const TABLE_COLUMNS = [
        { id: "p", desktopDisplay: "Matches", mobileDisplay: "P" },
        { id: "w", desktopDisplay: "Won", mobileDisplay: "W" },
        { id: "l", desktopDisplay: "Lost", mobileDisplay: "L" },
        // { id: "t", desktopDisplay: "Draw", mobileDisplay: "D" },
        {id : "nr" , desktopDisplay : "No Result" , mobileDisplay : "NR"},
        {id : "nrr" , desktopDisplay : "NRR"  , mobileDisplay : "NRR"},
        { id: "pts", desktopDisplay: "Points", mobileDisplay: "Pts" },
        

    ]

    const handleClick = () => {
      window.location.href = `/sports/ipl/points-table`
        
    }

    return (
        <>
            <div style={{ borderRadius: "10px",

                border:"1px solid rgba(255, 255, 255, 0.3)",
                cursor : "pointer"
             }}
             
             onClick={handleClick}>
                <table
                    style={{
                        width: "100%",

                        borderCollapse: "collapse",
                        textAlign: "center",
                        marginTop: "10px",
                        border: "1px solid #FFFFFF4D",
                        borderRadius: "10px"


                    }}
                className='mt-0'
                >
                    <thead>
                        <tr style={{
                            // backgroundColor : "#016369",
                            textAlign:"left"
                        }}>
                            <th
                                style={{
                                    width: "50%",
                                    border: "0px",
                                    // backgroundColor : "#016369",
                                    // opacity : "10%",
                                    background: "#FFFFFF1A",

                                    color: "#FFFFFF",
                                    padding: "8px",
                                }}
                            className='fw-normal text-start'
                            >
                                <span>IPL पॉइंट्स टेबल</span>
                                {/* <span>Teams (Group {pointsData.standings.stage1.groups[0].name})</span> */}
                                
                            </th>
                            {TABLE_COLUMNS.map((col, index) => (
                                <th
                                    key={index}
                                    style={{
                                        width: `calc(50% / 7)`,
                                        borderWidth: "0px",

                                        padding: "8px",
                                        // backgroundColor : "#016369",
                                        // opacity : "10%",
                                        background: "#FFFFFF1A",

                                        color: "#FFFFFF",
                                        // fontSize : "20px"

                                    }}
                            className='fw-normal'

                                >
                                   {desktop ? col.desktopDisplay : col.mobileDisplay} 
                                    
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {pointsData.standings.stage1.groups[0].team.slice(0, 3).map((team, rowIndex) => (
                            <tr key={rowIndex} style={{
                                background: rowIndex % 2 == 1 ? "#FFFFFF1A" : "#016369",
                                // opacity : rowIndex%2 ==1 && "30%" 

                            }}>
                                <td
                                    style={{
                                        borderWidth: "0px",
                                        padding: "8px",
                                        fontSize: "18px",
                                        // color : "#777777"
                                    }}
                                >

                                    <div
                                        style={{
                                            // display: "grid",
                                            // gridTemplateColumns: "1fr 1fr",
                                            // gap: "10px",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            columnGap: "10px"
                                        }}
                                    >
                                        <span
                                            style={{
                                                // padding: "20px",
                                                textAlign: "right",
                                                // border: "1px solid #ccc",
                                                // backgroundColor: "#f9f9f9",
                                            }}
                                        >
                                            <img style={{
                                                maxWidth: "22px",
                                                maxHeight: "22px"
                                            }} src={TEAMS_OBJ[team.short_name] ? TEAMS_OBJ[team.short_name]   :`${IMAGE_BASE_URL}${team.id}.png`} />
                                        </span>
                                        <span
                                            style={{
                                                // padding: "20px",
                                                textAlign: "left",
                                                // border: "1px solid #ccc",
                                                // backgroundColor: "#f9f9f9",
                                            }}
                                        >
                                            <span>{desktop ? team.name : team.short_name} {team?.is_qualified == 'True' && '(Q)'}</span>
                                        </span>
                                    </div>




                                </td>
                                {TABLE_COLUMNS.map((col, colIndex) => (
                                    <td
                                        key={colIndex}
                                        style={{
                                            borderWidth: "0px",
                                            padding: "8px",
                                            // textAlign : colIndex == 0 && "left",
                                            fontSize: "18px",
                                            // color : "#777777"
                                        }}
                                    >

                                        {team[col.id]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>  
            <style jsx>{`
    table {
    width: 100%;
    border-spacing: 0; 
    border-collapse: separate;
    border-radius: 10px;
    overflow: hidden !important;
}

table tr:last-child td:last-child {
    border-bottom-right-radius:10px;
}

table tr:last-child td:first-child {
    border-bottom-left-radius:10px;
}

table tr:first-child th:first-child,
table tr:first-child td:first-child {
    border-top-left-radius:10px
}

table tr:first-child th:last-child,
table tr:first-child td:last-child {
    border-top-right-radius:10px
}

table tr th:first-child,
table tr td:first-child {
   border-left: 2px solid #B2DBF4;
}
table tr:first-child th,
table tr:first-child td {
   border-top: 2px solid #B2DBF4;
}
    
    
    `}</style>
        </>
    )
}

export default WidgetTable
