import React, { useEffect, useMemo, useState } from "react";
// import DisplayScore from './DisplayScore'
// import axios from 'axios';
import dayjs from "dayjs";
import { SERIES_ID_IPL, TEAMS_OBJ } from "constants/cricket";
import axios from "axios";

function WidgetScorecard({ item, getStatus, scoreData }) {
  const [matchData, setMatchData] = useState(null);
  // const [scoreData , setScoreData] = useState(null)
  const [isLive, setIsLive] = useState(false);
  const [liveId, setLiveId] = useState(null);
  const [liveData, setLiveData] = useState(null);
  const [upcoming , setIsUpoming] = useState(false)
  const [recent , setIsRecent] = useState(false)




  const isToday = (date) => {
    const today = new Date();
    const dateCheck = new Date(date);

    return (
      dateCheck.getDate() === today.getDate() &&
      dateCheck.getMonth() === today.getMonth() &&
      dateCheck.getFullYear() === today.getFullYear()
    );
  };

  function dateWithFormat(dateTime, format) {
    return dayjs(dateTime).format(format);
  }

  const filterBySeries = (allMatches) => {
    return allMatches.filter((match) => match.series_Id == SERIES_ID_IPL);
  };

  const onCardClick = async (matchId) => {
    
    //fetch the status of the match
    const response = await axios.get(
      `${
        process.env.NEXT_PUBLIC_BASE_URL
      }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
    );
    let fetchedData = response?.data ?? null;
    if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
      const data = fetchedData?.data?.response?.scorecard;
      let page = null
      if(data.is_live == 1){
        page = 'live'
      }
      else if(data.upcoming == 1){
        page = 'info'
      }
      else{
        page = 'summary'
      }
      window.location.href = `/sports/ipl/${data?.page_slug_st}/stats/${page}`

    }    
    
  }

  const Live = ({ it, api = "common" }) => {
    return (
      <div
        className="custom-match-card-matches"
        style={{
          background: "#FFFFFF1A",
          border: "1px solid #FFFFFF4D",
          cursor : "pointer"
        }}
        onClick={() => {
          onCardClick(matchData?.match_Id)
        }}
      >
        <div
          class="custom-match-header"
          style={{
            marginBottom: "0px",
          }}
        >
          <p>
            {it.seriesName}, {it.matchNumber}
          </p>
          {/* <span class="custom-live-badge">LIVE</span> */}
          <div>
            {" "}
            <span class="">LIVE</span>
            <img
              style={{ maxWidth: "30px" }}
              src={"/assets/images/Hotspot.gif"}
            />
          </div>
        </div>
        <hr
          style={{
            margin: "0px",
          }}
        />
         <div className="custom-teams-scores px-2 pt-2">
          <div className="d-flex align-items-center justify-content-between mb-2">

            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teamAShort] ? TEAMS_OBJ[it?.teamAShort] :it?.teamALogo} alt="PAK"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>

              <p
                className="m-0 p-0 ms-1 text-white"
              >
               {it?.teamAName}
              </p>
            </div>
            <p className='m-0 p-0 px-1 text-white '>
            {it?.teamAId == it?.whoIsBowling
                ? it?.bowlingTeamScore
                : it?.battingTeamScore}{" "}
              {it?.showStar == it?.teamAId && " *"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">

            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teamBShort] ? TEAMS_OBJ[it?.teamBShort] :it?.teamBLogo} alt="AUS"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>

              <p
                className="m-0 p-0 ms-1 text-white"
              >
                {it?.teamBName}
              </p>
            </div>
            <p className='m-0 p-0 px-1 text-white '>
            {it?.teamBId == it?.whoIsBowling
                ? it?.bowlingTeamScore
                : it?.battingTeamScore}{" "}
              {it?.showStar == it?.teamBId && " *"}
            </p>
          </div>

        </div>
     

        <p
          class="custom-match-status"
          style={{
            background: "#FFFFFF1A",
            color: "#FFFFFF",
          }}
        >
          {it.matchResult ? it.matchResult : it.matchStatus}
        </p>
      </div>
    );
  };
  const Upcoming = ({ it }) => {


    const imgA = useMemo(() => it.teama_flag , [])
    const imgB = useMemo(() => it.teamb_flag , [])

    return (
      <div
        className="custom-match-card-matches"
        style={{
          background: "#FFFFFF1A",
          border: "1px solid #FFFFFF4D",
          cursor : "pointer"
        }}
        onClick={() => {
          onCardClick(matchData?.match_id)
        }}
      >
        {/* Match Header */}
        <div className="custom-match-header" style={{ marginBottom: "0px" }}>
          <p>
            {it.seriesname}, {it.matchnumber}
          </p>
          {/* <span className="custom-live-badge">LIVE</span> */}
          <div>
            <span>UPCOMING</span>
          </div>
        </div>

        <hr style={{ margin: "0px" }} />

        {/* Teams and Scores */}
        <div className="custom-teams-scores px-2 pt-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teama_short] ? TEAMS_OBJ[it?.teama_short] :imgA}
                  alt="PAK"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>

              <p className="m-0 p-0 ms-1 text-white">{it.teama_short}</p>
            </div>
            <p className="m-0 p-0 px-1 text-white ">
              {isToday(it?.matchdate_ist)
                ? "Today"
                : dateWithFormat(new Date(it?.matchdate_ist), "dddd, MMM DD")}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teamb_short] ? TEAMS_OBJ[it?.teamb_short] :imgB}
                  alt="AUS"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>

              <p className="m-0 p-0 ms-1 text-white">{it.teamb_short}</p>
            </div>
            <p className="m-0 p-0 px-1 text-white ">
              {dateWithFormat(
                new Date(`1970-01-01T${it?.matchtime_ist?.split("(")?.[0]}`),
                "hh:mm A"
              )}
            </p>
          </div>
        </div>

        {/* Match Status */}
        <p
          className="custom-match-status"
          style={{
            background: "#FFFFFF1A",
            color: "#FFFFFF",
          }}
        >
          {it.matchresult ? it.matchresult : it.matchstatus}
        </p>
      </div>
    );
  };

  const Recent = ({ it, api = "common" }) => {
    return (
      <div
        className="custom-match-card-matches"
        style={{
          background: "#FFFFFF1A",
          border: "1px solid #FFFFFF4D",
          cursor: "pointer"
        }}
        onClick={() => {
          onCardClick(it?.match_id);
        }}
      >
        <div
          className="custom-match-header"
          style={{
            marginBottom: "0px",
          }}
        >
          <p>
            {it.tourname}, {it.matchnumber}
          </p>
          <div>
            <span className="">ENDED</span>
            {/* <img
              style={{ maxWidth: "30px" }}
              src={"/assets/images/Hotspot.gif"}
            /> */}
          </div>
        </div>
        <hr style={{ margin: "0px" }} />
        <div className="custom-teams-scores px-2 pt-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teama_short] ? TEAMS_OBJ[it?.teama_short] :it?.teama_flag}
                  alt="Team A"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>
              <p className="m-0 p-0 ms-1 text-white">{it?.teama_short}</p>
            </div>
            <p className='m-0 p-0 px-1 text-white '>
              {it?.teama_id == it?.inn_team_1 ? it?.inn_score_1.replace(/\//g, '-') : it?.inn_score_2.replace(/\//g, '-')} {it?.showStar == it?.teama_id && " *"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className={`d-flex align-items-center`}>
              <div style={{ width: "25px", height: "25px" }} className="me-2">
                <img
                  src={TEAMS_OBJ[it?.teamb_short] ? TEAMS_OBJ[it?.teamb_short] :it?.teamb_flag}
                  alt="Team B"
                  onError={(e) => {
                    e.target.src =
                      "https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/17.png";
                  }}
                  className="w-100 h-100"
                />
              </div>
              <p className="m-0 p-0 ms-1 text-white">{it?.teamb_short}</p>
            </div>
            <p className='m-0 p-0 px-1 text-white '>
              {it?.teamb_id == it?.inn_team_1 ? it?.inn_score_1.replace(/\//g, '-') : it?.inn_score_2.replace(/\//g, '-')} {it?.showStar == it?.teamb_id && " *"}
            </p>
          </div>
        </div>
        <p
          className="custom-match-status"
          style={{
            background: "#FFFFFF1A",
            color: "#FFFFFF",
          }}
        >
          {it.matchresult ? it.matchresult : it.matchstatus}
        </p>
      </div>
    );
  };
  

  const sortByDate = (matches) => {
    return matches.sort(
      (a, b) => new Date(a.matchdate_ist) - new Date(b.matchdate_ist)
    );
  };

  useEffect(() => {
    // const live = false
    //match is live
    if (
      scoreData?.live &&
      scoreData?.live?.channel?.live?.length > 0 &&
      filterBySeries(scoreData?.live?.channel?.live).length > 0
    ) {
      setIsLive(true);
      setIsUpoming(false)
      setIsRecent(false)
      const data = sortByDate(filterBySeries(scoreData?.live?.channel?.live));
      setMatchData(data[0]);
      setLiveId(data[0]?.match_Id);

      setLiveData({
        seriesName: data[0]?.series_name,
        matchNumber: data[0]?.matchnumber,
        teamAId: data[0]?.teama_Id,
        teamBId: data[0]?.teamb_Id,
        whoIsBowling: data[0]?.inn_team_1,
        whoIsBatting: data[0]?.inn_team_2,
        bowlingTeamScore: data[0]?.inn_score_1,
        battingTeamScore: data[0]?.inn_score_2,
        teamALogo: data[0]?.teama_flag,
        teamBLogo: data[0]?.teamb_flag,
        teamAName: data[0]?.teama_hn,
        teamBName: data[0]?.teamb_hn,
        teamAShort : data[0]?.teama_short,
        teamBShort : data[0]?.teamb_short,
        matchStatus: data[0]?.matchresult,
        matchResult: data[0]?.matchstatus,
        pageSlugSt : data[0]?.page_slug_st,
        showStar:
          data[0]?.inn_team_1 == data[0]?.teama_Id
            ? data[0]?.teama_Id
            : data[0]?.teamb_Id,
      });
    }
    else if(scoreData?.recent && scoreData?.recent?.count > 0){
      setMatchData(scoreData.recent.data[0]);
      setIsLive(false);
      setIsUpoming(false)
      setIsRecent(true)
      setLiveId(null);
    }
    //match is upcoming
    else if (scoreData?.upcoming && scoreData?.upcoming?.count > 0) {
      setMatchData(sortByDate(scoreData.upcoming.data)[0]);
      setIsLive(false);
      setIsUpoming(true)
      setIsRecent(false)
      setLiveId(null);
    }
   
  }, []);

  useEffect(() => {
    if (liveId) {
      const poolingLiveMatches = async () => {
        const response = await axios.get(
          `${
            process.env.NEXT_PUBLIC_BASE_URL
          }/api/getRecentMatch?id=${liveId}&nocache=${new Date().getTime()}`
        );
        let fetchedData = response?.data ?? null;

        if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
          // // setLiveData(fetchedData?.data?.response?.scorecard);
          // //update the array of carousel
          // setCarouselMatches(carouselMatches?.map(it => {
          //   if(it.channel.scorecard.match_Id == fetchedData?.data?.response?.scorecard?.match_Id){
          //     return item.channel.scorecard
          //   }
          //   else{
          //     return it
          //   }
          // }))
          const data = fetchedData?.data?.response?.scorecard;
          //set the match data of the live match
          setMatchData(data);
          setLiveData({
            seriesName: data?.seriesname,
            matchNumber: data?.matchnumber,
            teamAId: data?.teama_Id,
            teamBId: data?.teamb_Id,
            whoIsBowling: data?.current_bowling_team_id,
            whoIsBatting: data?.current_batting_team,
            bowlingTeamScore: data?.bowlingTeamStats,
            battingTeamScore: data?.battingTeamStats,
            teamALogo: data?.teama_logo,
            teamBLogo: data?.teamb_logo,
            teamAName: data?.teama_hn,
            teamBName: data?.teamb_hn,
            teamAShort : data?.teama_short,
            teamBShort : data?.teamb_short,
            matchStatus: data?.matchresult_hn,
            matchResult: data?.matchStatus_hn,
            pageSlugSt : data?.page_slug_st,
            showStar:
              data?.current_bowling_team_id == data?.teama_Id
                ? data?.teamb_Id
                : data?.teama_Id,
          });
        } else {
          // alert("Please refresh the page.");
          // Refresh the page after user clicks OK
          // window.location.reload();
        }
      };

      let interval = setInterval(() => {
        poolingLiveMatches();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [liveId]);
  return matchData ? (
    isLive ? (
      <Live it={liveData} />
    ) : upcoming ? (
      <Upcoming it={matchData} />) : recent ? <Recent it={matchData}/> : null
    )
   : null;
}

export default React.memo(WidgetScorecard);

// const Live = ({it , api = "common"}) => {
//   return <div className="custom-match-card-matches"
//   style={{
//       background: "#FFFFFF1A",
//       border: "1px solid #FFFFFF4D",

//   }}
//   >
//   <div class="custom-match-header" style={{
//       marginBottom : "0px"
//   }}>
//   <p>{it.series_name ?? it.seriesname }, {it.matchnumber}</p>
//   {/* <span class="custom-live-badge">LIVE</span> */}
//   <div>  <span class="">LIVE</span>
//   <img style={{ maxWidth: "30px" }} src={'/assets/images/Hotspot.gif'} />
//   </div>
//   </div>
//   <hr  style={{
//       margin : "0px"
//   }}/>
//   {api = 'common' ? <div class="custom-teams-scores">
//         <p style={{ display: "flex", justifyContent: "space-between" }}>
//           <strong><img src={it?.teama_flag ?? it?.teama_logo} alt="" /> {it?.teama_hn}</strong>

//           {/* SCORE OF TEAM A EITHER BOWLING OR BATTING   */}
//           <span>{it?.teama_Id == it?.inn_team_1 ? it?.inn_score_1 : it?.inn_score_2}</span>
//         </p>
//         <p style={{ display: "flex", justifyContent: "space-between" }}>
//           <strong><img src={it?.teamb_flag ?? it?.teamb_logo} alt="" /> {it?.teamb_hn}</strong>
//           {/* SCORE OF TEAM B EITHER BOWLING OR BATTING   */}

//           <span>{it?.teamb_Id == it?.inn_team_1 ? it?.inn_score_1 : it?.inn_score_2}</span>
//         </p>
//       </div>
//     :
//     <div class="custom-teams-scores">
//         <p style={{ display: "flex", justifyContent: "space-between" }}>
//           <strong><img src={it?.teama_logo} alt="" /> {it?.teama_hn}</strong>

//           {/* SCORE OF TEAM A EITHER BOWLING OR BATTING   */}
//           <span>{it?.teama_Id == it?.current_bowling_team_id ? it?.bowlingTeamStats : it?.battingTeamStats}</span>
//         </p>
//         <p style={{ display: "flex", justifyContent: "space-between" }}>
//           <strong><img src={it?.teamb_logo} alt="" /> {it?.teamb_hn}</strong>
//           {/* SCORE OF TEAM B EITHER BOWLING OR BATTING   */}

//           <span>{it?.teamb_Id == it?.current_bowling_team_id ? it?.bowlingTeamStats : it?.battingTeamStats}</span>
//         </p>
//       </div>

//     }
//   <p class="custom-match-status" style={{
//       background: "#FFFFFF1A",
//       color : "#FFFFFF"

//   }}>{it.matchresult ? it.matchresult : it.matchstatus}</p>
// </div>
// }
